<template>

<div>
<div class="mb-2 shadow-xl -mt-6">
<img src="https://tbff-assets.ams3.cdn.digitaloceanspaces.com/sbann_elearning.png" />

</div>

<div class="bg-white mt-2 pt-3 pb-4">
    <div class="grid grid-cols-1 md:grid-cols-3 gap-5 ml-3 mr-3">

        <router-link class="block" v-for="show in shows" :to="{ name: $route.name+'_item', params: { name: show.page_url, metaTitle: show.name } }" :key="show.page_url">
        <div class="rounded-lg shadow-lg bg-white overflow-hidden">
            <img :src="show.image" alt="" class="inset-0 w-full h-auto object-cover" />
            
            <!--
            <div class="p-4">
                <h1 class="text-sm font-semibold">
                    {{ show.name }}
                </h1>
            </div>
            -->

        </div>
        </router-link>


    </div>
</div>
</div>
</template>

<script>
import apiClient from "@/services/API";

export default {
  name: 'show_list',
    data () {
      return {
            shows: ''
      }
    },
    methods: {
      async fetchData() {
        try {
          const url = '/show_list'+ this.$route.path
          const response = await apiClient.get(url)
          const results = response.data
          this.shows = results
        } catch (error) {
          if (error.response) {
            // client received an error response (5xx, 4xx)
            console.log("Server Error:", error)
          } else if (error.request) {
            // client never received a response, or request never left
            console.log("Network Error:", error)
          } else {
            console.log("Client Error:", error)
          }
        }
      }
    },
    mounted() {
      this.fetchData()
    }
}
</script>
